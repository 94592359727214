<template>
  <div>
    <BreadCrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />

    <v-card :loading="loading">
      <v-card-title> {{ title }} </v-card-title>

      <div class="pa-5">
        <v-row>
          <v-col>
            <v-text-field
              label="Nome"
              v-model="form.firstName"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Sobrenome"
              v-model="form.lastName"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Data de nascimento"
              v-model="form.date_of_birth"
              type="date"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-radio-group v-model="form.genre" row>
              <v-radio label="Masculino" :value="1"></v-radio>
              <v-radio label="Feminino" :value="2"></v-radio>
              <v-radio label="Personalizado" :value="3"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="form.genre == 3">
            <v-text-field
              label="Gênero"
              v-model="form.custom_genre"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="form.GroupId"
              class="required"
              :items="[
                { text: 'Selecionar', value: null },
                { text: 'Aluno', value: 1 },
                { text: 'Professor', value: 2 },
                { text: 'Administrador', value: 3 },
              ]"
              label="Grupo"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="E-Mail"
              v-model="form.email"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Confirmar E-Mail"
              v-model="form.confirmEmail"
              :class="requiredIfInsert"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Senha"
              type="password"
              required
              v-model="form.password"
              :class="requiredIfInsert"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Confirmar senha"
              type="password"
              required
              v-model="form.confirmPassword"
              :class="requiredIfInsert"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn class="primary darken-1" @click="save()" :loading="loading">
              <v-icon left>mdi-content-save</v-icon> Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

      <v-dialog v-model="successDialog" max-width="400">
        <v-card>
          <v-card-text>
            <div class="text-center pa-4">
              <v-icon large color="success">mdi-check</v-icon>
            </div>

            <div class="text-center pa-3">
              <h3>Dados salvos com sucesso</h3>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeSuccessDialog()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <UserRooms class="mt-2" v-if="userRooms.length" :rooms="userRooms" />
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import errorHandler from "@/helpers/error_handler";
import { mapMutations, mapState } from "vuex";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";
import UserRooms from "@/components/Users/UserRooms.vue";

export default {
  directives: { mask },
  props: {
    title: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
  components: {
    BreadCrumbs,
    UserRooms,
  },
  data: () => ({
    loading: false,
    successDialog: false,
    valid: true,
    file: null,
    form: {
      admin: true,
    },
    userRooms: [],
  }),
  computed: {
    ...mapState("user", ["userData"]),
    userId() {
      return this.$route.params.id;
    },
    requiredIfInsert() {
      return this.mode === "insert" ? "required" : "not-required";
    },
  },
  methods: {
    ...mapMutations("user", ["setUser"]),
    validateForm() {
      const errors = [];

      if (!this.form.firstName || this.form.firstName.length < 3)
        errors.push("O campo NOME é obrigatório");

      if (!this.form.lastName || this.form.lastName.length < 3)
        errors.push("O campo SOBRENOME é obrigatório");

      if (
        this.mode === "insert" &&
        this.form.password &&
        this.form.password != this.form.confirmPassword
      )
        errors.push("Os campos SENHA e CONFIRMAR SENHA não conferem");

      if (!this.form.email) errors.push("O campo E-MAIL é obrigatório");

      if (
        this.mode === "insert" &&
        this.form.confirmEmail &&
        this.form.email != this.form.confirmEmail
      )
        errors.push("Os campos E-MAIL e CONFIRMAR E-MAIL não conferem");

      if (!this.form.genre) errors.push("O campo GÊNERO é obrigatório");
      else if (this.form.genre == 3 && !this.form.custom_genre)
        errors.push("Informe o gênero PERSONALIZADO");

      if (!this.form.date_of_birth)
        errors.push("O campo DATA DE NASCIMENTO é obrigatório");

      if (!this.form.GroupId) errors.push("O campo GRUPO é obrigatório");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/admin/usuarios/cadastrar`, {
          ...this.form,
        });

        this.clearForm();

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(`/admin/usuarios/${this.userId}`, {
          ...this.form,
        });

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.form = {
        admin: true,
      };
    },
    closeSuccessDialog() {
      this.successDialog = false;

      this.initializeForm();
    },
    async initializeForm() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`admin/usuarios/${this.userId}`);

        this.form = { ...res.data.userData };
        this.userRooms = [...res.data.userRooms];

        console.log(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  mounted() {
    if (this.mode === "update") this.initializeForm();
  },
};
</script>

<style>
.required label::after {
  content: " *";
  color: red;
}
</style>
